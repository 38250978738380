<template>
    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> {{ title }} </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <div style="text-align:center" v-if="absence">
                        <form :id="'modal-absence-form-' + modalId">
                            <div class="container col-md-10 d-grid gap-2">

                                <select v-model="absence.employee" class="form-select" aria-label="Default select example" disabled required>
                                    <option :value="absence.employee">{{absence.employee.user.first_name}} {{absence.employee.user.last_name}}</option>
                                </select>

                                <div class="form-floating">
                                    <input v-model="absence.start_date" class="form-control" type="date" :max="absence.end_date" @change="startDateChanged" placeholder="Početni datum:" aria-label="default input example" required>
                                    <label for="floatingTextarea">Početak</label>
                                </div>

                                <div class="form-floating">
                                    <input v-model="absence.end_date" class="form-control" type="date" :min="absence.start_date" @change="endDateChanged" placeholder="Krajnji datum:" aria-label="default input example">
                                    <label for="floatingTextarea">Kraj</label>
                                </div>
                                
                                <template v-if="totalDays">
                                    <h5 v-if="totalDays == Infinity">Na neodređeno</h5>
                                    <h5 v-else>{{totalDays}} Dana odsustva</h5>

                                </template>

                                <div class="form-floating">

                                    <select v-model="absence.absence_type" class="form-select" aria-label="Default select example" required>
                                        <option disabled :value="undefined">Razlog odsustva</option>
                                        <option value="VACATION">Odmor</option>
                                        <option value="SICKNESS">Bolovanje</option>
                                        <option value="RELIGIOUS">Verski praznik</option>
                                        <option value="OTHER">Drugi razlog</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        Razlog odsustva je obavezno polje.
                                    </div>
                                    <label for="floatingTextarea">Razlog</label>
                                </div>

                                <div v-if="mode == 'EDIT' && !isEmployee" class="form-floating">

                                    <select v-model="absence.absence_status" class="form-select" aria-label="Default select example" required>
                                        <option v-if="absence.absence_status == 'DEFAULT'" value="DEFAULT"> <font-awesome-icon icon="check" />Aktivan</option>
                                        <option value="APPROVED"> <font-awesome-icon icon="check" />Odobri</option>
                                        <option v-if="changed" value="ADMIN_CHANGE">Predlozi izmenu</option>
                                        <option value="REJECTED">Nije odobreno</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        Status odsustva je obavezno polje.
                                    </div>
                                    <label for="floatingTextarea">Status</label>
                                </div>

                                <div v-if="mode == 'ADD' && !isEmployee" class="form-floating">

                                    <select v-model="absence.absence_status" class="form-select" aria-label="Default select example" required>
                                        <option value="REQUESTED"> <font-awesome-icon icon="check" />Čeka odobrenje</option>
                                        <option value="APPROVED"> <font-awesome-icon icon="check" />Odobri</option>
                                    </select>
                                    <div class="invalid-feedback">
                                        Status odsustva je obavezno polje.
                                    </div>
                                    <label for="floatingTextarea">Status</label>
                                </div>

                                <div class="form-floating">
                                    <textarea :disabled="!isEmployee && mode=='EDIT'" v-model="absence.request_message" style="height: 100px" class="form-control" type="text" placeholder="Zahtev:" aria-label="default input example" />
                                    <label for="floatingInput">Razlog:</label>
                                </div>

                                <div class="form-floating">
                                    <textarea :disabled="isEmployee" v-model="absence.comment" style="height: 100px" class="form-control" type="text" placeholder="Komentar:" aria-label="default input example" />
                                    <label for="floatingInput">Komentar:</label>
                                </div>

                                <ul class="list-group list-group-flush">
                                    <li v-if="absence.approved_by && absence.approved_at" class="list-group-item">Odobrio: {{absence.approved_by.first_name}} {{absence.approved_by.last_name}} <small>{{formatDateTime(absence.approved_at)}}</small></li>
                                    <li v-if="absence.created_by && absence.created_at" class="list-group-item">Napravio zahtev: {{absence.created_by.first_name}} {{absence.created_by.last_name}} <small>{{formatDateTime(absence.created_at)}}</small></li>
                                    <li v-if="absence.updated_at" class="list-group-item">Poslednja izmena: <small>{{formatDateTime(absence.updated_at)}}</small></li>
                                </ul>

                            </div>
                        </form>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    <button :disabled="totalDays && (totalDays < 0 || totalDays > 360) && totalDays != Infinity" @click="confirm" type="button" class="btn btn-primary"> Potvrdi </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import UtilService from '@/service/UtilService.js';
    import moment from "moment";

    export default {

        props: {
            modalId: String,
            title: String,
            mode: String,
            absence: Object
        },

        data() {
            return {
                changed: false,
            }
        },

        computed: {
            
            ...mapState([
                'user',
            ]),

            isEmployee() {
                return this.user.role != "ADMIN" && this.user.id === this.absence.employee.user.id
            },

            totalDays() {
                if (!this.absence) return undefined;
                if (!this.absence.start_date) return undefined;
                if (!this.absence.end_date) return Infinity;
                return this.daysBetween();

            }
        },

        methods: {
            formatDateTime: UtilService.formatDateTime,

            daysBetween() {
                let a = UtilService.convertMoment(this.absence.start_date);
                let b = UtilService.convertMoment(this.absence.end_date);
                return this.workingDaysBetween(a,b)
            },

            workingDaysBetween(a, b) {
                if (a > b) return 0

                let workDays = 0
                for (var m = moment(a); m.diff(b, 'days') <= 0; m.add(1, 'days')) {
                    if (!(m.day() == 6 || m.day() == 0)) {
                        workDays += 1
                    }
                }
                return workDays
            },
            
            startDateChanged() {
                this.changedRequest();
            },

            endDateChanged() {
                this.changedRequest();
            },

            changedRequest() {
                if (this.mode != "EDIT") return

                this.changed = true;

                if (this.absence.absence_status == "REQUESTED")
                    this.absence.absence_status = "ADMIN_CHANGE"
            },

            confirm() {
                if (this.validate()) this.$emit('confirmed');
            },

            validate() {
                let form = document.getElementById('modal-absence-form-' + this.modalId);
                if (this.validateForm(form) === false)
                    return;
                    
                return true;
            },

            load() {
                // if (this?.absence?.absence_status == "REQUESTED")
                //     this.absence.absence_status = null;
                 
            },

            validateForm(form) {
                form.classList.add('was-validated');
                return form.checkValidity();
            },
        },

        

    }
</script>

<style>
p {
    font-size: 140%;
}
</style>